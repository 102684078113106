.block {
    padding: 2rem 4rem;
}

.header {
    background-color: #fafafa;
}

.header > .title {
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 400;
}

.title {
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .block {
        padding: 1rem;
    }

    .header > .title {
        font-size: 2rem;
    }

    .title {
        font-size: 1.5rem;
    }
}
