@font-face {
    font-family: "Mazius";
    src: url("https://static1.squarespace.com/static/5e260b901324310e95f28460/t/5e26104f732aba3bf2868c0a/1579552847688/MAZIUSREVIEW20.09-Italic.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: inherit;
}

html, body, #root, .App {
    height: 100%;
}

.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    flex: 1;
}

body {
    box-sizing: border-box;
    font-family: Helvetica, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}
