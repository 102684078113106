.title {
    padding: 2rem 4rem;
    font-size: 3rem;
    font-weight: 400;
    background-color: #fafafa;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
    width: 50%;
}

.label {
    margin-bottom: 0.8rem;
    font-size: 1rem;
}

.input {
    margin-bottom: 0.8rem;
    padding: 0.5rem;
    background-color: #fafafa;
    border-radius: 0.5rem;
    border: 0.1rem solid #dfdfdf;
}

.button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.2rem;
    font-size: 1rem;
    background-color: #EA4227;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #e62b1e;
}

.error {
    margin-top: 1rem;
    color: #e62b1e;
}

@media screen and (max-width: 768px) {
    .title {
        padding: 2rem;
    }

    .container {
        width: 100%;
        padding: 2rem;
    }
}
